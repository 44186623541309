.slide {
    cursor: pointer;
    border-bottom: 3px solid white;
    border-top: 2px solid white;

    .owl-stage-outer {
        .owl-stage {
            .owl-item {
                // max-height: 400px !important;
            }
        }
    }
}

.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    // max-height: 400px !important;
    object-fit: contain;
}
